export const imagesWEBP = {
  passengerLogo:`${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/3wPassenger1.svg`,
  cargoLogo:`${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/3wCargo1.svg`,
  cargoLogo4w:`${process.env.NEXT_PUBLIC_CDN_LINK}fourWheeler/4wCargo2.svg`,
  turnoBrandLogo:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/turno-brand.svg`,
  brandLogo:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/turno-red.svg`,
  sideNavIconSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/sideNavIcon.svg`,
  sideNavIconSvg2:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/sideNavIcon2.svg`,
  callIconSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/call.svg`,
  mapSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/map.svg`,
  mailIconSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/mail.svg`,
  batterySvg:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/battery.svg`,
  goodiesSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/goodies.svg`,
  modificationSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/modification.svg`,
  porterSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/porter.svg`,
  buybackSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/buyback.svg`,
  changeVehicleSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/changeVehicle.svg`,
  doorServiceSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/door-service.svg`,
  rickshawSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}logos/rickshaw.svg`,
  callUsSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/call2.svg`,
  rightArrowSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/right-arrow.svg`,
  rangeSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/range_svg.svg`,
  priceSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/price_svg.svg`,
  batteryCapacitySvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/battery_icon-1.svg`,
  batteryWarrantySvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/battery-icon-2.svg`,
  loadCapacitySvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/load_icon.svg`,
  whatsappSvg1:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/whatsapp1.svg`,
  whatsappSvg2:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/whatsapp-icon.svg`,
  testDriveSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/testDrive.svg`,
  facebookSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/facebook.svg`,
  instaSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/insta.svg`,
  linkedinSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/linkedin.svg`,
  youtubeSvg:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/youtube.svg`,

  cashIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/cash-coin.svg`,
  resaleIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/resale.svg`,
  serviceIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/service.svg`,
  testDriveIcon :`${process.env.NEXT_PUBLIC_CDN_LINK}icons/test-drive.svg`,
  financeIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/finance.svg`,

  specificationsIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/specifications.svg`,
  specsRangeIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/specs/range.svg`,
  specsPowerIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/specs/bolt.svg`,
  specsEngineIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/specs/engine.svg`,
  specsTorqueIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/specs/torque.svg`,
  specsTopSpeedIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/specs/speed.svg`,
  specsChargingTimeIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/specs/charging.svg`,
  specsChasisIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/specs/chasis.svg`,
  specsDriveIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/specs/steer.svg`,
  specsGradabilityIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/specs/grade.svg`,
  specsWeightIcon:`${process.env.NEXT_PUBLIC_CDN_LINK}icons/specs/weight.svg`,
  
  //backgound images
  refferalPageBackground: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/referral_page_bg.webp`,
  diwaliImage: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/diwali_image.webp`,
  L3LandingPageBanner1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/l3LandingPageBanner1.webp`,
  L3LandingPageBanner2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/l3LandingPageBanner2.webp`,
  L3LandingPageBanner3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/l3LandingPageBanner3.webp`,

  //homepage v2
  category3wP: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/icons/category3wP.webp`,
  category3wC: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/icons/category3wC.webp`,
  category4wC: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/icons/category4wC.webp`,
  subCategoryFlatBed: `${process.env.NEXT_PUBLIC_CDN_LINK}icons/flatBed.webp`,
  subCategoryFullBody: `${process.env.NEXT_PUBLIC_CDN_LINK}icons/fullBody.webp`,
  subCategoryPickup: `${process.env.NEXT_PUBLIC_CDN_LINK}icons/pickup.webp`,

  //homepage v2 Vehicle Loan Info
  treoZor_148_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/treoZor_148_no_bg.webp`,

  
  //vehicle select card Images
  treo_Plus_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/treo_plus_bg.webp`,
  treo_Plus_bg_2x: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/treo_plus_bg_2x.webp`,
  montra_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/montra_bg.webp`,
  piaggio_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/piaggio_bg.webp`,
  zor_grand_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/zor_grand_bg.webp`,
  bajaj_P_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/bajaj_P_bg.webp`,
  hiload_pv_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/hiload_pv_bg.webp`,
  eka_k1_bg : `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/eka_k1_bg.webp`,
  storm_ev_bg : `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/storm_ev_bg.webp`,

  // Vehicle Slider Images
  rec_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/rec_no_bg.webp`,
  storm_ev_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/storm_ev_no_bg.webp`,
  treo_plus_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/treo_plus_no_bg.webp`,
  ecity_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/ecity_no_bg.webp`,
  hiload_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/hiload_no_bg.webp`,
  montra_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/montra_no_bg.webp`,



  // Vehicle emi Slider Images
  hiload_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/hiload_city_bg.webp`,
  extra_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/extra_city_bg.webp`,
  treo_plus_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/treo_plus_city.webp`,
  ecity_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/ecity_city_bg.webp`,
  rec_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/rec_city_bg.webp`,
  montra_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/montra_city_bg.webp`,

  testDriveBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/test_drive_banner.webp`,

  //homepage v2
  // category3wP: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/icons/category3wP.webp`,
  // category3wC: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/icons/category3wC.webp`,
  // category4wC: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/icons/category4wC.webp`,

  //homepage v2 Vehicle Loan Info
  treoZor_148_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/treoZor_148_no_bg.webp`,

  
  //vehicle select card Images
  // treo_Plus_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/treo_plus_bg.webp`,
  // treo_Plus_bg_2x: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/treo_plus_bg_2x.webp`,
  // montra_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/montra_bg.webp`,
  // piaggio_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/piaggio_bg.webp`,
  // zor_grand_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/zor_grand_bg.webp`,
  // bajaj_P_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/bajaj_P_bg.webp`,
  // hiload_pv_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/hiload_pv_bg.webp`,
  // eka_k1_bg : `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/eka_k1_bg.webp`,
  // storm_ev_bg : `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/storm_ev_bg.webp`,

  // Vehicle Slider Images
  // rec_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/rec_no_bg.webp`,
  // storm_ev_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/storm_ev_no_bg.webp`,
  // treo_plus_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/treo_plus_no_bg.webp`,
  // ecity_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/ecity_no_bg.webp`,
  // hiload_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/hiload_no_bg.webp`,
  // montra_no_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/montra_no_bg.webp`,



  // // Vehicle emi Slider Images
  // hiload_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/hiload_city_bg.webp`,
  // extra_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/extra_city_bg.webp`,
  // treo_plus_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/treo_plus_city.webp`,
  // ecity_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/ecity_city_bg.webp`,
  // rec_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/rec_city_bg.webp`,
  // montra_city_bg: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/VehicleCardImages/montra_city_bg.webp`,

  // testDriveBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/test_drive_banner.webp`,


  homeBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/banner.webp`,
  brandBlogBannerEnglish: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/blogBannerEnglish1.webp`,
  homePiaggio: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/piaggio.webp`,
  homeEtrio: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/etrio.webp`,
  homeOsm: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/osm.webp`,
  homeZor_Grand: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/zor_grand.webp`,
  homeTreo_Zor: `${process.env.NEXT_PUBLIC_CDN_LINK}VehicleCarousel/Mahindra+Corousel+imgs/compressed/mahindra_full_body.jpg`,
  homeFlipImages: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/flip_images.webp`,
  launchOfferImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/launchOffer.webp`,
  vehiclePageBanner1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/banner1.webp`,
  vehiclePageBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/desktopBanner.webp`,
  vehiclePageBanner2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/banner2.webp`,
  vehiclePageLoanBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/osm/CheckEMI.webp`,
  vehiclePageLoanBannerHindi: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/banner/desktop_banner_loan_hindi.webp`,
  vehiclePageLoanBannerKannada: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/banner/desktop_banner_loan_kannada.webp`,
  vehiclePageLoanBannerMobile: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/piaggio/EMIMweb.webp`,
  vehiclePageLoanBannerMobileimage: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Euler/small_vehicle-EMI.webp`,
  vehiclePageLoanBannerMobileHindi: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/banner/loan_banner_mweb_hindi.webp`,
  vehiclePageLoanBannerMobileKannada: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/banner/loan_banner_mweb_kannada.webp`,
  locationBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/banner/map_3010_594.webp`,
  videoCall: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/WhatsAppCall.webp`,
  newLaunchPassengerBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/JustLaunched/PassengerNewLaunchBanner.webp`,

  brandBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandBanner.webp`,
  mahindraBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandBanner/MahindraHeroBanner.webp`,
  mahindraBannerHindi: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandBanner/mahindrabanner_hindi.webp`,
  mahindraBannerKannada: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandBanner/mahindrabanner_kannada.webp`,
  osmBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandBanner/osmBanner.webp`,
  etrioBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandBanner/etrioBanner.webp`,
  eulerBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandBanner/eular_main_banner_mobile.webp`,
  piaggioBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandBanner/PiaggioHeroBanner.webp`,
  altigreenBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandBanner/altigreenBanner.webp`,
  allBrandsBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandBanner/Allbrands.webp`,

  osm: {
    osmDesktopBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/osm/OSMTOPBANNER.webp`,
    osmMobileBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/osm/SOMTOPBANNERMOBILE.webp`,
    osmVehicle1: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/osm/OSMIMAGE1.webp`,
    osmVehicle2: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/osm/OSM.webp`,
    osmVehicle3: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/osm/SOMIMAGEFROSTER.webp`,
  },
  piaggio: {
    piaggioDesktopBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/piaggio/PiaggioTopBanner.webp`,
    piaggioMobileBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/piaggio/PiaggiotopbannerMobile.webp`,
    piaggioVehicle1: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/piaggio/PiaggioApeE-XtraFax.webp`,
    piaggioVehicle2: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/piaggio/PiaggioApeE-XtraFXMax.webp`,
  },
  Etrio: {
    etrioDesktopBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/E-Trio/E-trioTopBnner.webp`,
    etrioMobileBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/E-Trio/EtriotopbannermWeb.webp`,
    etrioVehicle1: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/E-Trio/KwhE-TrioTuoroMax%2B%2B-1.webp`,
    etrioVehicle2: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/E-Trio/KwhE-TrioTuoroMax%2B%2B.webp`,
  },
  Euler: {
    eulerDesktopBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/brandBanner/eular_main_banner_desktop.webp`,
    eulerMobileBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Euler/EulertopbannermWeb.webp`,
    eulerHindiDesktopBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Euler/eular_hindi_desktop_banner.webp`,
    eulerHindiMobileBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Euler/eular_hindi_mobile_main_banner.webp`,
    eulerVehicle1: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Euler/eular_cards_DV.webp`,
    eulerVehicle2: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Euler/eular_cards_PV.webp`,
  },
  Mahindra: {
    mahindraDesktopBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Mahindra/MahindraTopBnner.webp`,
    mahindraDesktopBannerHindi: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Mahindra/desktop_banner_mahindra_hindi.webp`,
    mahindraDesktopBannerKannada: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Mahindra/desktop_banner_mahindra_kannada.webp`,
    mahindraMobileBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Mahindra/MahindraatopbannermWeb.webp`,
    mahindraVehicle1: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Mahindra/MahindraTreoZor.webp`,
    mahindraVehicle2: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Mahindra/MahindraTreoZorGrand.webp`,
  },

  AltiGreen: {
    altigreenVehicle: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Altigreenfullbody.webp`,
    altigreenDesktopBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreenTopBnner.webp`,
    altigreenMobileBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreentopbannermWeb.webp`,
  },

  greaves: {
    greavesVehicle: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/greavesfullbody.webp`,
    greavesDesktopBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/GreavesTopBanner.webp`,
    greavesMobileBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greavestopbannermobile.webp`,
    greavesVehicle1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/greavesfullbody.webp`,
    greavesVehicle2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/greavesfullbody.webp`,
  },

  bajaj: {
    bajajVehicle1: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Bajaj/bajaj_flow-01.webp`,
    bajajVehicle2: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Bajaj/bajaj_flow-02.webp`,
    bajajVehicle3: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Bajaj/bajaj_flow-03.webp`,
    bajajVehicle4: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/Bajaj/bajaj_flow-04.webp`,
  },
  MahindraTreoPlus: {
    MahindraTreoPlusVehicle1: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/MahindraTreoPlus/mahindraTreoPlusFlow1.webp`,
    MahindraTreoPlusVehicle2: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/MahindraTreoPlus/mahindraTreoPlusFlow2.webp`,
    MahindraTreoPlusVehicle3: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/MahindraTreoPlus/waterAndDustProofImage.webp`,
    MahindraTreoPlusVehicle4: `${process.env.NEXT_PUBLIC_CDN_LINK}seo/MahindraTreoPlus/mahindraTreoPlusFlow4.webp`,
  },

  // Dummy images
  dummyImages: {
    flat_bed: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/faltbed.webp`,
    full_body: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/fullbody.webp`,
    pickup: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
    flat_bedUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
    full_bodyUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
    pickupUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
    flat_bedselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
    full_bodyselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
    pickupselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
  },

  quotation: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation.pdf`,

  brandLogos: {
    osm: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/brand-logo/osmicon.webp`,
    mahindra: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/brand-logo/Mahindraicon.webp`,
    piaggio: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/brand-logo/Piaggioicon.webp`,
    etrio: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/brand-logo/etrioicon.webp`,
    euler: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/brand-logo/eulericon.webp`,
  },
  // noentry delhi - BrandSOMTOPBANNERMOBILE.webp
  evBrands: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/EVBrands.webp`,
  firstScroll: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/1stscroll.webp`,
  secondScroll: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/2ndscroll.webp`,
  thirdScroll: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/3rdscroll.webp`,
  allBrands: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/BrandsthatTrust.webp`,
  noEntry: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/NOENTRY.webp`,
  testDrive: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/TestDrive.webp`,
  review1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/PRR1.webp`,
  review2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/PRR2.webp`,
  review3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/PRR3.webp`,
  noEntryBannerHindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/no_entry_banner.webp`,
  noEntryScroll1Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/card_1_no_entry-01.webp`,
  noEntryScroll2Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/card_1_no_entry-02.webp`,
  noEntryScroll3Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/card_1_no_entry-03.webp`,
  noEntryReview1Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/review-01.webp`,
  noEntryReview2Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/noentry/review-02.webp`,

  //dummy inages forproduct slider
  dummyBody: [
    {
      type: "flat_bed",
      name: "Flat Bed",
      imageURLSelected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
      imageURLUnSelected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
    },
    {
      type: "full_body",
      name: "Full Body",
      imageURLSelected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
      imageURLUnSelected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
    },
    {
      type: "pickup",
      name: "Pick Up",
      imageURLSelected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
      imageURLUnSelected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
    },
  ],

  // monsoon
  monsoonSlider1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/monsoon/MonsoonSlider1.webp`,
  monsoonSlider2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/monsoon/MonsoonSlider2.webp`,
  monsoonSlider3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/monsoon/MonsoonSlider3.webp`,
  waterproofEV: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/monsoon/WaterproofEV.webp`,
  advantageEV: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/monsoon/advantageEV.webp`,
  monsoonBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/monsoon/monsoonBanner.webp`,

  //dairy
  etrioMilk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/Etriomilk.webp`,
  eulerMilk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/Eulermilk.webp`,
  mahindraMilk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/Mahindra.webp`,
  osmMilk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/OSMmilk.webp`,
  piaggioMilk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/Piaggiomilk.webp`,
  topbannermilk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/Topbannermilk.webp`,
  dataInfoMilk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/2ndimage.webp`,
  sliderMilk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/Milkslider.webp`,
  slider1Milk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/Milkslider1.webp`,
  slider2Milk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/Milkslider2.webp`,
  rating1Milk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/Rating1.webp`,
  rating2Milk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/Rating2.webp`,
  rating3Milk: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dairy/Rating3.webp`,

  // freedom-india
  augustSlider1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/freedom-india/15thAugustslider1.webp`,
  augustSlider2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/freedom-india/15thAugustslider2.webp`,
  augustSlider3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/freedom-india/15thAugustslider3.webp`,
  EtrioF: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/freedom-india/EtrioF.webp`,
  EulerF: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/freedom-india/EulerF.webp`,
  MahindraF: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/freedom-india/MahindraF.webp`,
  OSMf: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/freedom-india/OSMf.webp`,
  PiaggioF: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/freedom-india/PiaggioF.webp`,
  TopbannerF: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/freedom-india/TopbannerF.webp`,
  dataBanner4k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/freedom-india/4k.webp`,

  //2w to 3w - kannada
  twotothreeSlider1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wKannada/2wheelersliderkannada1.webp`,
  twotothreeSlider2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wKannada/2wheelersliderkannada2.webp`,
  twotothreeSlider3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wKannada/2wheelersliderkannada3.webp`,
  twotothreeTopBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wKannada/Topbanner2Wheelerkannada.webp`,
  twowheelUser: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wKannada/627kannada.webp`,
  Etrio2w: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3w/Etrio2w.webp`,
  Osm2w: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3w/Osm2w.webp`,
  Euler2w: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3w/Euler2w.webp`,
  Mahindra2w: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3w/Mahindra2w.webp`,
  Piaggio2w: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3w/Piaggio2w.webp`,

  // Loan process steps
  loanProcess: [
    `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/step1.webp`,
    `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/step2.webp`,
    `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/step3.webp`,
    `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/step4.webp`,
    `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/step5.webp`,
  ],
  //Hindi 2w to 3w
  twoWheelerHindi1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wHindi/2w-3w-scroll1.webp`,
  twoWheelerHindi2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wHindi/2w-3w-scroll2.webp`,
  twoWheelerHindi3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wHindi/2w-3w-scroll3.webp`,
  Topbanner2Wheelerhindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wHindi/2w-3w-banner.webp`,
  Ratinghindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wHindi/Ratinghindi.webp`,
  Ratinghindi1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wHindi/Ratinghindi1.webp`,
  Ratinghindi2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wHindi/ratinghindi3.webp`,
  hindi647: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wHindi/hindi647.webp`,

  //varmahalaxmi

  varmahalaxmi4k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/varamahalakshmi/4000%2Bkannada.webp`,
  TopbannerVaralakshmikannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/varamahalakshmi/TopbannerVaralakshmikannada.webp`,
  Varalakshmisliderkannada1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/varamahalakshmi/Varalakshmisliderkannada1.webp`,
  Varalakshmisliderkannada2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/varamahalakshmi/Varalakshmisliderkannada2.webp`,
  Varalakshmisliderkannada3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/varamahalakshmi/Varalakshmisliderkannada3.webp`,

  // kannada catering
  milk517: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/catering/milk517.webp`,
  Milkslider1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/catering/Milkslider1.webp`,
  Milkslider2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/catering/Milkslider2.webp`,
  Milkslider3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/catering/Milkslider3.webp`,
  Milktopbanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/catering/Milktopbanner.webp`,

  popup2wto3w: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wHindi/Popup.webp`,
  popup2wto3wkannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/2wto3wHindi/popup2.webp`,

  //Mahindra Treo Zor-useCase

  treousecase0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/usecase/Treousecase0.webp`,
  treousecase1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/usecase/Treousecase1.webp`,
  treousecase2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/usecase/Treousecase2.webp`,
  treousecase3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/usecase/Treousecase3.webp`,

  bestDeals: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/BESTDEAL.webp`,
  bestPrice: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/BESTPRICE.webp`,
  goodDeal: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/gooddeal.webp`,
  multibrandstore: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/Multibrandstore.webp`,

  rating1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/Rating1.webp`,
  rating2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/Rating2.webp`,
  rating3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/Rating3.webp`,
  rating4: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/Rating4.webp`,

  business1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/Businesstreo0.webp`,
  business2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/Businesstreo1.webp`,
  business3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/Businesstreo2.webp`,
  business4: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/mahindra-zor/others/Businesstreo3.webp`,

  //Zor Grand

  businessZorG1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/others/Businessgrand0.webp`,
  businessZorG2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/others/Businessgrand1.webp`,
  businessZorG3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/others/Businessgrand2.webp`,
  businessZorG4: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/others/Businessgrand3.webp`,

  //Use Cases
  zrgrandusecase0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/usecase/MZrandusecase.webp`,
  zrgrandusecase1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/usecase/MZrandusecase1.webp`,
  zrgrandusecase2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/usecase/MZrandusecase2.webp`,
  zrgrandusecase3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/zor-grand/usecase/MZrandusecase3.webp`,

  // Piaggio FX
  businessFX1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/others/Businessfx0.webp`,
  businessFX2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/others/Businessfx1.webp`,
  businessFX3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/others/Businessfx2.webp`,
  businessFX4: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/others/Businessfx3.webp`,

  piaggioFxusecase0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/usecase/Piaggiofxusecase0.webp`,
  piaggioFxusecase1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/usecase/Piaggiofxusecase1.webp`,
  piaggioFxusecase2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/usecase/Piaggiofxusecase2.webp`,
  piaggioFxusecase3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx/usecase/Piaggiofxusecase3.webp`,

  //Piaggio FX Max
  businessFXMax1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/others/businesspiaggiomax0.webp`,
  businessFXMax2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/others/businesspiaggiomax1.webp`,
  businessFXMax3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/others/businesspiaggiomax2.webp`,
  businessFXMax4: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/others/businesspiaggiomax3.webp`,

  piaggioFxMaxusecase0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/usecases/piaggiomaxusecase0.webp`,
  piaggioFxMaxusecase1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/usecases/piaggiomaxusecase1.webp`,
  piaggioFxMaxusecase2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/usecases/piaggiomaxusecase2.webp`,
  piaggioFxMaxusecase3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/piaggio-fx-max/usecases/piaggiomaxusecase3.webp`,

  //Euler Hiload
  hiloadusecase0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/usecase/HiloadUseCase0.webp`,
  hiloadusecase1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/usecase/HiloadUseCase1.webp`,
  hiloadusecase2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/usecase/HiloadUseCase2.webp`,
  hiloadusecase3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/usecase/HiloadUseCase3.webp`,

  businesshiload0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/others/businessh0.webp`,
  businesshiload1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/others/businessh1.webp`,
  businesshiload2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/others/businessh2.webp`,
  businesshiload3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/hiload/others/businessh3.webp`,

  //Altigreen NeEv
  neevUsecase0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/usecase/neEvUsecase0.webp`,
  neevUsecase1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/usecase/neEvUsecase1.webp`,
  neevUsecase2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/usecase/neEvUsecase2.webp`,
  neevUsecase3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/usecase/neEvUsecase3.webp`,

  businessN0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/others/businessN0.webp`,
  businessN1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/others/businessN1.webp`,
  businessN2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/others/businessN2.webp`,
  businessN3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/altigreen-neEv/others/businessN3.webp`,

  // Greaves Eltra
  greavesEltrsusecase0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/usecase/gravesusecase0.webp`,
  greavesEltrsusecase1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/usecase/gravesusecase1.webp`,
  greavesEltrsusecase2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/usecase/gravesusecase2.webp`,
  greavesEltrsusecase3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/usecase/gravesusecase3.webp`,

  businessGreavesEltra0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/others/businessg0.webp`,
  businessGreavesEltra1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/others/businessg1.webp`,
  businessGreavesEltra2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/others/businessg2.webp`,
  businessGreavesEltra3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/others/businessg3.webp`,

  greavesLaunch: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Greaves/others/greavesLaunch.webp`,

  // Etrio
  businesstouroMax1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/others/businesse0.webp`,
  businesstouroMax2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/others/businesse1.webp`,
  businesstouroMax3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/others/businesse2.webp`,
  businesstouroMax4: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/others/businesse3.webp`,

  touroMaxusecase0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/usecase/etriousecase0.webp`,
  touroMaxusecase1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/usecase/etriousecase1.webp`,
  touroMaxusecase2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/usecase/etriousecase2.webp`,
  touroMaxusecase3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/etrio-touro-max/usecase/etriousecase3.webp`,

  //montra super auto
  vehicleBenefitsMontraSuperAuto0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/benefits/vehicleBenefits0.webp`,
  vehicleBenefitsMontraSuperAuto1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/benefits/vehicleBenefits1.webp`,
  vehicleBenefitsMontraSuperAuto2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/benefits/vehicleBenefits2.webp`,
  vehicleBenefitsMontraSuperAuto3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/montra/benefits/vehicleBenefits3.webp`,

  //piaggio ecity fx
  vehicleBenefitsPiaggioEcityFx0: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/benefits/vehicleBenefits0.webp`,
  vehicleBenefitsPiaggioEcityFx1: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/benefits/vehicleBenefits1.webp`,
  vehicleBenefitsPiaggioEcityFx2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/benefits/vehicleBenefits2.webp`,
  vehicleBenefitsPiaggioEcityFx3: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/Piaggio-EcityFx/benefits/vehicleBenefits3.webp`,

  //myth buster
  cardBenefits: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/cardBenefits.webp`,
  mythFinance1: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/Finance1.webp`,
  mythFinance2: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/Finance2.webp`,
  mythFinance3: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/Finance3.webp`,
  mythFinance4: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/Finance4.webp`,
  mythFinance5: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/Finance5.webp`,
  theTurno: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/Theturno.webp`,

  mythVehicle0: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/v0.webp`,
  mythVehicle1: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/v1.webp`,
  mythVehicle2: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/v2.webp`,

  mythVehicle3: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/v3.webp`,
  mythVehicle4: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/v4.webp`,
  mythVehicle5: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/v5.webp`,
  connectTitle: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/connectTitle.webp`,

  kannadaMythTop: `${process.env.NEXT_PUBLIC_CDN_LINK}mythBuster/mythTopKannada.webp`,

  //Tractorjunction
  evTrucksBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/topbannertruckj.webp`,
  evTrucksBanner1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/topbannertruckj2.webp`,
  evTrucksBanner2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/topbannertruckj3.webp`,
  evTrucksBannerKan: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/topbannertruckjkannada.webp`,
  evTrucksBannerKan1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/topbannertruckjkan2.webp`,
  evTrucksBannerKan2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/topbannertruckjkan3.webp`,

  evTrucksScroll1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/scroll1.webp`,
  evTrucksScroll2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/scroll2.webp`,
  evTrucksScroll3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/scroll3.webp`,
  evTrucksSold: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/627%2Bvehicle.webp`,

  evTrucksSoldKan: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/kannada627.webp`,
  evTrucksScrollKan1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/scrollkannada1.webp`,
  evTrucksScrollKan2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/scrollkannada2.webp`,
  evTrucksScrollKan3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/scrollkannada3.webp`,

  kannadaRating1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/Ratingk1.webp`,
  kannadaRating2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/Ratingk2.webp`,
  kannadaRating3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/truck-junction/hindi/Ratingk3.webp`,

  //loan-emi

  loanFinance1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/finance1.webp`,
  loanFinance2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/finance2.webp`,
  loanFinance3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/finance3.webp`,
  loanFinance4: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/finance4.webp`,
  loanFinance5: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/finance5.webp`,
  loanFinance6: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/finance6.webp`,
  loanFinance7: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/finance7.webp`,
  loanFinanceTopBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/financetopbanner.webp`,

  loanDoc1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/documents1.webp`,
  loanDoc2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/documents2.webp`,
  loanDoc3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/documents3.webp`,
  loanDoc4: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/documents4.webp`,
  loanDoc5: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/finance-google+tags/documents5.webp`,

  //charging
  chargingStation: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/charging-station.webp`,

  //tco revamp
  p1decor: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/p1-decor.webp`,
  tcoReview1: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/review1.webp`,
  tcoReview2: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/review2.webp`,
  tcoReview3: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/review3.webp`,
  tcodiya: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/diya.webp`,
  tcoFinanceMade: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/finance-made.webp`,
  tcoSavings: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/savings.webp`,
  tcoWhenText: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/when-text.webp`,
  tcoThroughTurno: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/through-turno.webp`,
  tcobg1: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/tcobg1.webp`,
  tcoDiya: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/diya.webp`,
  dieselGadi: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/dieselgadi.webp`,
  diyadecor: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/diyadecor.webp`,

  //Ice vs EV
  iceEv: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/ev.webp`,
  iceBadge: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/badge.webp`,
  iceCelebrate: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/celebrate.webp`,
  iceDieselEv: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/dieselEv.webp`,
  iceMoney: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/money-icon.webp`,
  festivePrice: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/festivePrice.webp`,
  festiveInfo: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/festiveInfo.webp`,
  iceEvDiesel: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/ev-vs-diesel.webp`,
  iceCarousel1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/carousel1.webp`,
  iceCarousel2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/carousel2.webp`,
  ice3Years: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/3years.webp`,
  icefoldBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/icefoldBanner.webp`,
  iceMostEfficient: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/most-efficient.webp`,
  customerDeenadayal: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/customer_deenadayal.webp`,
  customerNaveen: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/customer_naveen.webp`,
  customerRamesh: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/customer_ramesh.webp`,
  iconHand: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/iconHand.webp`,
  iceDiesel: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/diesel_vehicle.webp`,
  iceMedal: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/medal.webp`,
  iceImgev: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/imgev.webp`,
  iceConfetti: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/confetti.webp`,
  iceThumbsUp: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/thumbs_up.webp`,
  iceCoin: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/ice-vs-ev/coin.webp`,

  //kannada -tco
  tcoKnfoldtxt1: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/kannada/kannada-fold1-txt1.webp`,
  tcoKnfoldtxt2: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/kannada/kannada-fold1-txt2.webp`,
  tcoKnfoldtxt3: `${process.env.NEXT_PUBLIC_CDN_LINK}tco-v2/kannada/kannada-fold1-txt3.webp`,

  //goods-ev
  goodsEvBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/goodsBanner.webp`,
  goodsLocation: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/multiBrandLocation.webp`,
  lowestEmiTag: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/lowestEmi.webp`,
  topSellingTag: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/topSelling.webp`,

  // Buyback Battery

  buybackBatteryBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/assuredBuyback.webp`,
  howSteps: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/howSteps.webp`,
  buybackl1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/l1.webp`,
  buybackl2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/l2.webp`,
  buybackl3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/l3.webp`,
  buybackl4: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/l4.webp`,
  buybackl5: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/l5.webp`,
  buybackl6: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/l6.webp`,
  buybackl7: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/l7.webp`,
  lightBrew: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/lightBrew.webp`,
  lithiumIon: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/lithiumIon.webp`,
  benefit1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/g1.webp`,
  benefit2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/g2.webp`,
  benefit3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/buyBack/g3.webp`,

  //pre sales

  preSaleBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/sales/pre-sales/banner1.webp`,
  preSale1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/sales/pre-sales/1st.webp`,
  preSale2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/sales/pre-sales/2nd.webp`,
  preSale3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/sales/pre-sales/3rd.webp`,

  preSale3years: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/sales/pre-sales/3year.webp`,

  // post sales

  postSaleBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/sales/post-sales/banner2.webp`,
  postSaleP1Text: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/sales/post-sales/p1text.webp`,
  postSaleCarousel1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/sales/post-sales/carousel1.webp`,
  postSaleCarousel2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/sales/post-sales/carousel-2.webp`,

  //Business
  fishDistribution: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/etrioTouroMax/fishes.webp`,
  milkDistributors: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/piaggioFx/milk_container.webp`,
  waterDistributors: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/piaggioFx/water_can.webp`,
  ecommerce: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/treoZor/ecommerce.webp`,
  cylinder: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/piaggioFx/gas_cylinders.webp`,
  vegetableFruits: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/goods-ev/ecommerce/treoZor/vegetable_crates.webp`,

  //marketing
  marketingBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/electric_commercial_english.webp`,
  hindiMarketingBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/electric_commercial_hindi.webp`,
  electricPasengeterBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/electric_passenger_english.webp`,
  fourWheelerCargoBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/4W_cargo.webp`,
  hindiElectricPassengerBanners: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/electric_passenger_hindi.webp`,
  marketingTick: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/ElectricCommercialGoodsIcons/Assured+Financing-01.webp`,
  marketingHeart: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/ElectricCommercialGoodsIcons/sticker-02.webp`,
  marketingDelivery: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/ElectricCommercialGoodsIcons/sticker-06.webp`,
  markertingEmi: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/ElectricCommercialGoodsIcons/sticker-07.webp`,
  marketingInfo: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/marketing/info-1.webp`,
  marketingBetter: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/marketing/better.webp`,
  marketingVariety: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/marketing/exchangemod.webp`,
  marketingr1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/marketing/r1.webp`,
  marketingr2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/marketing/r2.webp`,
  marketingr3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/marketing/r3.webp`,
  marketingreviewTitle: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/marketing/reviewTitle.webp`,

  //subsidy banner
  subsidyBannerMobile: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/subsidyBannerMobile.webp`,
  subsidyBannerDesktop: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/subsidyBannerDesktop.webp`,

  //home page banner
  homePageBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/web_banner_desk.webp`,
  homePageBannerMobile: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/web_banner_mob.webp`,
  homePageBanner2: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/bannerV2.webp`,
  homePageBanner2Mobile: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/HomePage/bannerMobV2.webp`,
  homePageBannerV2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/homePageBanner.webp`,
  newBanner: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/homePageBannerNew.webp`,

  //emi-11k-hindi
  hindiBannerEmi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/hindi-banner.webp`,
  scroll1Emi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/scroll1.webp`,
  scroll2Emi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/scroll2.webp`,
  scroll3Emi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/scroll3.webp`,
  hindi953: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/hindi953.webp`,
  ratingHindi1Emi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/Ratinghindi1.webp`,
  ratingHindi2Emi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/Ratinghindi2.webp`,
  /* Passenger vehicles landing page image links for delhi region */
  passengerVehicles: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/passenger_vehicles_main_banner-01.webp`,
  passengerVehiclesScroll1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/passenger-vehicles/passenger_vehicle_scroll_card_01.webp`,
  passengerVehiclesScroll2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/passenger-vehicles/passenger_vehicle_scroll_card_02.webp`,
  passengerVehiclesScroll3: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/passenger-vehicles/passenger_vehicle_scroll_card_03.webp`,
  bajajPassenger: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/passenger-vehicles/bajaj_passenger.webp`,
  montraSuperAuto: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/passenger-vehicles/montra_super_auto.webp`,
  mahindraTreo: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/passenger-vehicles/mahindra_treo_plus.webp`,
  piaggioPassenger: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/passenger-vehicles/piaggio_pasenger.webp`,
  PassengerVehicleRatingReview1: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/passenger-vehicles/passengerReview-01.webp`,
  PassengerVehicleRatingReview2: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/passenger-vehicles/PassengerReview-02.webp`,
  smallVehiclePassgerImage: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/passenger-vehicles/small_vehicle_98_80-01.webp`,

  //emi-11k-kannada
  kannadaBannerEmi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/kannada-banner.webp`,
  scroll1Emi11kKan: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/scroll1Ka.webp`,
  scroll2Emi11kKan: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/scroll2Ka.webp`,
  scroll3Emi11kKan: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/scroll3Ka.webp`,
  kannada953: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/ka953.webp`,
  ratingKan1Emi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/RatingKa1.webp`,
  ratingKan2Emi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/RatingKa2.webp`,

  //emi-11k-passenger-kannada
  kannadaBannerEmi11kPassenger: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k-passenger/main_banner_3w_ev_emi_11k_01.webp`,
  scroll1Emi11kPassengerKan: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k-passenger/carousel_card_3w_ev_emi_11k_01.webp`,
  scroll2Emi11kPassengerKan: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k-passenger/carousel_card_3w_ev_emi_11k_02.webp`,
  scroll3Emi11kPassengerKan: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k-passenger/carousel_card_3w_ev_emi_11k_03.webp`,

  //emi-11k-Telugu
  teluguBannerEmi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/teluguBanner.webp`,
  scroll1Emi11kTel: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/scroll1Telugu.webp`,
  scroll2Emi11kTel: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/scroll2Telugu.webp`,
  scroll3Emi11kTel: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/scroll3Telugu.webp`,
  telugu953: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/telugu953.webp`,
  ratingTel1Emi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/RatingTelugu1.webp`,
  ratingTel2Emi11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/RatingTelugu2.webp`,

  //emi-11k-vehicle-images
  etrio3w11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/Etrio3w.webp`,
  piaggio3w11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/Piaggio3w.webp`,
  mahindra3w11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/Mahindra3w.webp`,
  greaves3w11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/Greaves3w.webp`,
  euler3w11k: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/emi-11k/Euler3w.webp`,

  //50k-dp
  hindiBanner50kDp: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dp50k/50K_Hindi.webp`,
  hindiScroll150kDp: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dp50k/50K_Hindi_scroll1.webp`,
  kannadaBanner50kDp: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dp50k/50K_Kannada.webp`,
  kannadaScroll150kDp: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dp50k/50K_Kannada_scroll1.webp`,
  teluguBanner50kDp: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dp50k/50K_Telugu.webp`,
  teluguScroll150kDp: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/dp50k/50K_Telugu_scroll1.webp`,

  //rent-vs-buy
  rentVsBuyBannerHindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyBannerHindi.webp`,
  rentVsBuyScroll1Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyScroll1Hindi.webp`,
  rentVsBuyScroll2Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyScroll2Hindi.webp`,
  rentVsBuyScroll3Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyScroll3Hindi.webp`,
  rentVsBuyVehicleSoldHindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/776Hindi.webp`,
  rentVsBuyReview1Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyReviewHindi1.webp`,
  rentVsBuyReview2Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyReviewHindi2.webp`,

  rentVsBuyBannerKannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyBannerKannada.webp`,
  rentVsBuyScroll1Kannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyScroll1Kannada.webp`,
  rentVsBuyScroll2Kannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyScroll2Kannada.webp`,
  rentVsBuyScroll3Kannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyScroll3Kannada.webp`,
  rentVsBuyVehicleSoldKannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/776Kannada.webp`,
  rentVsBuyReview1Kannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyReviewKannada1.webp`,
  rentVsBuyReview2Kannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyReviewKannada2.webp`,

  rentVsBuyBannerTelugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyBannerTelugu.webp`,
  rentVsBuyScroll1Telugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyScroll1Telugu.webp`,
  rentVsBuyScroll2Telugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyScroll2Telugu.webp`,
  rentVsBuyScroll3Telugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyScroll3Telugu.webp`,
  rentVsBuyVehicleSoldTelugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/776Telugu.webp`,
  rentVsBuyReview1Telugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyReviewTelugu1.webp`,
  rentVsBuyReview2Telugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/rentVsBuy/rentVsBuyReviewTelugu2.webp`,

  //logistics
  logisticsBannerHindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticBannerHindi.webp`,
  logisticsScroll1Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticCard1Hindi.webp`,
  logisticsScroll2Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticCard2Hindi.webp`,
  logisticsScroll3Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticCard3Hindi.webp`,
  logisticsVehicleSoldHindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticVehicleSoldHindi.webp`,
  logisticsReview1Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticReview1Hindi.webp`,
  logisticsReview2Hindi: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticReview2Hindi.webp`,

  logisticsBannerKannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticBannerKannada.webp`,
  logisticsScroll1Kannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticCard1Kannada.webp`,
  logisticsScroll2Kannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticCard2Kannada.webp`,
  logisticsScroll3Kannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticCard3Kannada.webp`,
  logisticsVehicleSoldKannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticVehicleSoldKannada.webp`,
  logisticsReview1Kannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticReview1Kannada.webp`,
  logisticsReview2Kannada: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticReview2Kannada.webp`,

  logisticsBannerTelugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticBannerTelugu.webp`,
  logisticsScroll1Telugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticCard1Telugu.webp`,
  logisticsScroll2Telugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticCard2Telugu.webp`,
  logisticsScroll3Telugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticCard3Telugu.webp`,
  logisticsVehicleSoldTelugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticVehicleSoldTelugu.webp`,
  logisticsReview1Telugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticReview1Telugu.webp`,
  logisticsReview2Telugu: `${process.env.NEXT_PUBLIC_CDN_LINK}brand/logistics/logisticReview2Telugu.webp`,

  // Electric commercial goods icon images
  linkStickerImageIcom: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/ElectricCommercialGoodsIcons/sticker-03.webp`,
  cargoSticketImageIcom: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/ElectricCommercialGoodsIcons/sticker-01.webp`,
  //Electric Passenger icons images:
  passengerStickerImageIcon: `${process.env.NEXT_PUBLIC_CDN_LINK}WhyTurno/electric-passenger/sticker_passenger.webp`,


  //vehiclePage
  //Mahindra Treo Plus
  mahindraTreoPlusImage1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Mahindra_Treo_Plus/mahindra_treo_plus_287-1.webp`,
  mahindraTreoPlusImage2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Mahindra_Treo_Plus/mahindra_treo_plus_287-2.webp`,
  mahindraTreoPlusImage3: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Mahindra_Treo_Plus/mahindra_treo_plus_287-3.webp`,
  mahindraTreoPlusImage4: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Mahindra_Treo_Plus/mahindra_treo_plus_287-4.webp`,
  mahindraTreoPlusImage5: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Mahindra_Treo_Plus/mahindra_treo_plus_287-5.webp`,

  //Piaggio Ecity
  piaggioEcityImage1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Piaggio_Ape_Ecity/ape_ecity_287-1.webp`,
  piaggioEcityImage2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Piaggio_Ape_Ecity/ape_ecity_287-2.webp`,
  piaggioEcityImage3: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Piaggio_Ape_Ecity/ape_ecity_287-3.webp`,

  //Bajaj Rec
  bajajRecImage1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Bajaj_Rec/rec_287-1.webp`,
  bajajRecImage2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Bajaj_Rec/rec_287-2.webp`,
  bajajRecImage3: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Bajaj_Rec/rec_287-3.webp`,

  //Montra Super Auto
  montraSuperAutoImage1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Montra_Super_Auto/super_auto_287-1.webp`,
  montraSuperAutoImage2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Montra_Super_Auto/super_auto_287-2.webp`,
  montraSuperAutoImage3: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Passenger/Montra_Super_Auto/super_auto_287-3.webp`,

  //Eka K15
  ekaK15Image1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Four_Wheeler_Cargo/Eka_K15/eka_k15_287-1.webp`,
  ekaK15Image2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Four_Wheeler_Cargo/Eka_K15/eka_k15_287-2.webp`,
  ekaK15Image3: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Four_Wheeler_Cargo/Eka_K15/eka_k15_287-3.webp`,
  ekaK15Image4: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Four_Wheeler_Cargo/Eka_K15/eka_k15_287-4.webp`,

  //Euler Storm Ev
  eulerStormEvImage1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Four_Wheeler_Cargo/Euler_Storm_Ev/storm_ev_287-1.webp`,
  eulerStormEvImage2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Four_Wheeler_Cargo/Euler_Storm_Ev/storm_ev_287-2.webp`,

  //Bajaj Maxima XL
  bajajMaximaXLImage1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Bajaj_Maxima_XL/recCargo_287-1.webp`,
  bajajMaximaXLImage2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Bajaj_Maxima_XL/recCargo_287-2.webp`,
  bajajMaximaXLImage3: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Bajaj_Maxima_XL/recCargo_287-3.webp`,

  //Euler Hiload 
  eulerHiloadImage1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Euler_Hiload/hiload_287-1.webp`,
  eulerHiloadImage2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Euler_Hiload/hiload_287-2.webp`,
  eulerHiloadImage3: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Euler_Hiload/hiload_287-3.webp`,

  //Mahindra Treo Zor
  mahindraTreoZorImage1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Mahindra_Treo_Zor/treo_zor_287-1.webp`,
  mahindraTreoZorImage2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Mahindra_Treo_Zor/treo_zor_287-2.webp`,
  mahindraTreoZorImage3: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Mahindra_Treo_Zor/treo_zor_287-3.webp`,

  //Mahindra Zor Grand
  mahindraZorGrandImage1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Mahindra_Zor_Grand/zor_grand_287-1.webp`,
  mahindraZorGrandImage2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Mahindra_Zor_Grand/zor_grand_287-2.webp`,
  mahindraZorGrandImage3: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Mahindra_Zor_Grand/zor_grand_287-3.webp`,

  //Piaggio Extra Fx
  piaggioExtraFxImage1: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Piaggio_Extra_FX/eXtra_fx_287-1.webp`,
  piaggioExtraFxImage2: `${process.env.NEXT_PUBLIC_CDN_LINK}HomePageV2/Vehicles/Cargo/Piaggio_Extra_FX/eXtra_fx_287-2.webp`,

};
